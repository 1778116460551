@charset "utf-8";

body {
    box-sizing: border-box !important;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    font-family: Arial, Verdana, sans-serif;
}

html {
    box-sizing: border-box !important;
    scroll-behavior: smooth;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
}

.element_footer_one {
    background-color: #050634;
    background-image: url('/img/marca_agua_footer.png');
    background-position: 2% center;
    background-repeat: no-repeat;
    background-size: 25%;
    color: white;
    font-family: "Montserrat", Sans-serif;
    width: 100%;
}

.element_form_auth {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
}

.font-link-nav {
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.section_main {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    min-height: 100vh;
    padding: 0;
    width: 100%;
}

@media(max-width:320px) {
    .element_footer_one {
        background-position: center;
        background-size: 90%;
    }
}

@media(min-width:321px) and (max-width:575px) {
    .element_footer_one {
        background-position: center;
        background-size: 90%;
    }
}

@media(min-width:576px) and (max-width:767px) {
    .element_footer_one {
        background-position: center;
        background-size: 90%;
    }
}
